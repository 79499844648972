import { Card } from "../constants";
export const master_card_saga = (data) => {
    return ({
        type: Card.GET_CARD_SAGA,
        payload: data
    });
};
export const master_card = (data) => {
    return ({
        type: Card.GET_CARD,
        payload: data
    });
};
export const card_loading = () => {
    return ({
        type: Card.CARD_LOADING
    });
};
export const create_account_saga = (data) => {
    return ({
        type: Card.CREATE_ACCOUNT_SAGA,
        payload: data
    });
};
export const add_card_saga = (data) => {
    return ({
        type: Card.ADD_CARD_SAGA,
        payload: data
    });
};
export const delete_card_saga = (data) => {
    return ({
        type: Card.DELETE_CARD_SAGA,
        payload: data
    });
};
export const add_card = (data) => {
    return ({
        type: Card.ADD_CARD,
        payload: data
    });
};
export const create_account_act = (data) => {
    return ({
        type: Card.CREATE_ACCOUNT,
        payload: data
    });
};
export const card_inputs_value = (data) => {
    return ({
        type: Card.CARD_INPUTS_VALUE,
        payload: data
    });
};
export const submit = () => {
    return ({
        type: Card.SUBMITTED,
    });
};
export const create_card_loading = () => {
    return ({
        type: Card.CREATE_CARD_LOADING,
    });
};
export const history_loading = () => {
    return ({
        type: Card.HISTORY_LOADING
    });
};
export const get_history = (data) => {
    return ({
        type: Card.GET_HISTORY,
        payload: data
    });
};
export const get_history_saga = (data) => {
    return ({
        type: Card.GET_HISTORY_SAGA,
        payload: data
    });
};